import React from 'react'
import { ButtonContent } from '../global/boton/ButtonContent'


const CardProductBusqueda = ({ imagen, name, price, phone }) => {
    return (
        <div className='w-[250px] text-center shadow-lg pb-5 rounded-lg bg-slate-100'>
            <div className='h-[250px] w-full pb-5'>
                <img
                    src={imagen}
                    alt="not found"
                    className='object-cover w-full h-full rounded-t-lg'
                />
            </div>
            <p>{name}</p>
            <p>${price}</p>
            <ButtonContent btnUrl={`https://wa.me/1${phone}`} />
        </div>
    )
}

export default CardProductBusqueda