import React, { useContext } from 'react'
import { GlobalDataContext } from "../../../context/context";
import { Link } from 'react-router-dom';
import { HiChevronRight, HiOutlineMail, HiOutlineCalendar } from 'react-icons/hi';
import { FiClock, FiMapPin, FiSmartphone } from 'react-icons/fi';


const FooterContent = () => {
  const { rpdata } = useContext(GlobalDataContext);
  const yearsActual = new Date();

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const menu = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Gallery",
      link: "/gallery",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  // agregar item servicio al array de dbMenu
  const serv = {
    name: `Products`,
    link: `/products`,
    child: false,
  }

  if (!rpdata?.autoGntLandingFromService) {
    const num = menu.length - 2
    menu.splice(num, 0, serv)
  }

  // agregar item Video al array de dbMenu

  const yt = {
    name: `Our Videos`,
    link: `/our-videos`,
    child: false,
  }

  if (rpdata?.ytGallery?.linkCanalYT) {
    const num = menu.length - 1
    menu.splice(num, 0, yt)
  }
  //  fin de agregar pestaña de Video

  // agregar item reviews al array de dbMenu

  const el = {
    name: `Reviews`,
    link: `/reviews`,
    child: false,
  }

  if (rpdata?.reviews?.activo && rpdata?.reviews?.isHomeOnly === false) {
    const num = menu.length - 1
    menu.splice(num, 0, el)
  }
  //  fin de agregar pestaña de reviews

  return (
    <div className='bg-zinc-900 pt-16 pb-10'>
      <div className='w-4/5 mx-auto border-b'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>

          <div className='flex flex-col justify-center text-center md:text-start pb-14 md:pb-0'>
            <p className='text-gray-200 capitalize'>Find us here:</p>
            <ul className="flex justify-center md:justify-start gap-4 ">
              {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <li key={index} className=" duration-300 ease-in-out textHeader5 bg-zinc-600 hover:scale-105 px-3 py-1">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <i className={`fab fa-${item.icon} text-[15px]`} aria-hidden="true" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className='pb-10 md:pb-0'>
            <img
              src={rpdata?.dbPrincipal?.logo}
              className="w-full md:w-[300px] object-cover mx-auto"
              alt="no found logo"
              loading="lazy"
            />
          </div>

          <div className='flex flex-col justify-center pb-5 md:pb-0'>
            <p className='text-gray-200 capitalize text-center md:text-start'>Payment methods:</p>
            <img
              src={rpdata?.tiposPago?.[0]?.img}
              className='w-full md:w-[30%]'
              alt="no found"
            />
          </div>

        </div>
      </div>

      <div className='w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-10'>
        <div className='pb-10 md:pb-0'>
          <h4 className='text-gray-100 text-[20px] pb-3'>About</h4>
          <p className='text-gray-200'>{rpdata?.dbAbout?.[1].text.substring(0, 224)}</p>
        </div>

        <div className='ml-0 md:ml-5 pb-10 md:pb-0'>
          <h4 className='text-gray-100 text-[20px] pb-3'>Categories</h4>
          <ul>
            {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
              return (
                rpdata?.simpleWidgets?.[3]?.activo ?
                  <li className="py-2 duration-300 ease-in-out text-gray-200 hover:text-[#ebdd70]" key={index}>
                    <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} className="flex">
                      <HiChevronRight className="self-center" />
                      <span className="pl-2">{item.name}</span>
                    </a>
                  </li>
                  :
                  <li className="py-2 duration-300 ease-in-out text-gray-200 hover:text-[#ebdd70]" onClick={goToTop} key={index}>
                    <Link
                      to={
                        rpdata?.autoGntLandingFromService ?
                          `/${item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`
                          : "/products"
                      }
                      className="flex"
                    >
                      <HiChevronRight className="self-center" />
                      <span className="pl-2">{item.name}</span>
                    </Link>
                  </li>
              );
            })}
          </ul>
        </div>

        <div className='pb-10 md:pb-0'>
          <h4 className='text-gray-100 text-[20px] pb-3'>Navigation</h4>
          <ul className="flex flex-col gap-5">
            {menu.map((item, index) => {
              return (
                <li className="duration-300 ease-in-out text-gray-200 hover:text-[#ebdd70]" onClick={goToTop} key={index}>
                  <Link to={item.link} className="flex gap-1">
                    <HiChevronRight className="self-center" />
                    <span className="pl-2">{item.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        <div className='ml-0 md:-ml-10'>
          <h4 className='text-gray-100 text-[20px] pb-3'>Contact</h4>
          <ul>
            <li className='flex gap-3 text-gray-200 pb-5'>
              <FiMapPin className='text-[#ebdd70]' />
              <span>{rpdata?.dbPrincipal?.location[0]?.address}</span>
            </li>
            <li className='flex gap-3 text-gray-200 pb-5'>
              <FiSmartphone className='text-[#ebdd70]' />
              {
                rpdata?.dbPrincipal?.phones?.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={`tel:+1${item.phone}`}
                      className="duration-300 ease-in-out hover:text-[#ebdd70]"
                    >
                      {item.phone}
                    </a>
                  );
                })}
            </li>
            <li className='flex gap-3 text-gray-200 pb-5'>
              <HiOutlineMail className='text-[#ebdd70]' />
              <a
                href={`mailto:${rpdata?.dbPrincipal?.emails[0].email}`}
                className="duration-300 ease-in-out hover:text-[#ebdd70]"
              >
                {rpdata?.dbPrincipal?.emails[0].email}
              </a>
            </li>
            <li className='flex gap-3 text-gray-200 pb-5'>
              <HiOutlineCalendar className='text-[#ebdd70]' />
              {
                rpdata?.dbPrincipal?.workdays.length > 1 ? (
                  <div className="flex flex-col">
                    <span>
                      {rpdata?.dbPrincipal?.workdays?.[0].day}
                    </span>
                    <span>
                      {rpdata?.dbPrincipal?.workHours?.[0].hour}
                    </span>
                  </div>
                ) : (
                  <span>
                    {rpdata?.dbPrincipal?.workdays?.[0].day}
                  </span>
                )
              }
            </li>
            <li className='flex gap-3 text-gray-200 pb-5'>
              <FiClock className='text-[#ebdd70]' />
              {
                rpdata?.dbPrincipal?.workdays.length > 1 ? (
                  <div className="flex flex-col">
                    <span>
                      {rpdata?.dbPrincipal?.workdays?.[1].day}
                    </span>
                    <span>
                      {rpdata?.dbPrincipal?.workHours?.[1].hour}
                    </span>
                  </div>
                ) : (
                  <span>
                    {rpdata?.dbPrincipal?.workHours?.[0].hour}
                  </span>
                )
              }
            </li>
          </ul>
        </div>
      </div>

      <div className='w-4/5 mx-auto border-t text-center pt-1'>
        <p className='text-gray-200'>
          <span className='text-[#ebdd70]'>
            ©{yearsActual.getFullYear()}
          </span>, Copyright By
          <span className='text-[#ebdd70]'>
            {rpdata?.dbPrincipal?.name}
          </span>. All Rights Reserved</p>
      </div>
    </div>
  )
}

export default FooterContent